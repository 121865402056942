<template>
  <div>
    <div class="header">
      <h4>用户管理</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 20px">
      <el-col
        style="margin-top: 12px"
        :span="6"
        v-access
        data-ctrl="userEnterCard"
      >
        <el-card shadow="always" v-prev path="/system/user/list">
          <el-button size="medium" type="text">
            <i class="iconbussiness-man-fill iconfont"></i>合作创客</el-button
          >
        </el-card>
      </el-col>
      <el-col style="margin-top: 12px" :span="6">
        <el-card shadow="always" v-prev path="/system/enterprise/list">
          <el-button size="medium" type="text">
            <i class="iconbussiness-man-fill iconfont"></i>企业客户</el-button
          >
        </el-card>
      </el-col>
      <el-col
        style="margin-top: 12px"
        :span="6"
        v-access
        data-ctrl="subUserEnterCard"
      >
        <el-card shadow="always" v-prev path="/system/setting/subuser">
          <el-button size="medium" type="text">
            <i class="iconbussiness-man-fill iconfont"></i>企业子帐户</el-button
          >
        </el-card>
      </el-col>
      <el-col style="margin-top: 12px" :span="6">
        <el-card shadow="always" v-prev path="/system/user/personnelComparison">
          <el-button size="medium" type="text">
            <i class="iconbussiness-man-fill iconfont"></i
            >创客信息对比</el-button
          >
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      index: 1
    };
  },
  methods: {
    getUserStastic() {
      this.index++;
    }
  }
};
</script>
